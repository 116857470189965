.tooltip {
  position: absolute;
  z-index: 3;
  display: none;
  width: 150px;
  margin: 0;
  background-color: white;
  border: solid;
  border-width: 2px;
  border-radius: 5px;
  padding: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 1;
}

.tooltip.show {
  display: block;
}

.timeline-container {
  position: relative;
  margin-left: 0px;
  border-radius: 10px;
}

.timeline-container svg {
  pointer-events: all;
  overflow: visible;
}

.timeline-container svg:focus {
  outline: none;
}

.timeline-container .bubbleTimeline-tooltip-container {
  overflow: visible;
}

.timeline-container .bubbleTimeline-tooltip-container .bubbleTimeline-tooltip {
  text-align: center;
  border: 2px solid var(--color-blue-ghost-green);
  border-radius: 0.25rem;
  margin-left: 20px;
  padding: 0.2rem;
  font-size: 0.9375rem;
  background: #ffffff;
  width: max-content;
}

@media (min-width: 3000px) {
  .timeline-container .tick text {
    font-size: 2.4375rem;
    fill: #cdcdcd;
  }

  .timeline-container .yAxis .tick text {
    transform: translate(-20px, 0px);
  }

  .timeline-container .xAxis .tick text {
    transform: translate(0px, 20px);
  }

  .timeline-container text.x.label {
    font-size: 2.5rem;
    fill: #cdcdcd;
    transform: translate(-20px, 8px);
  }
}
