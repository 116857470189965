@media (max-width: 992px) {
  .attack-table {
    padding-right: 15px;
    padding-left: 20px;
  }
}

.attack-table {
  overflow: hidden;
}

.attack-table {
  background: var(--color-main-background);
}

.attack-table {
  text-align: center;
}

.attack-table table,
.attack-table th,
.attack-table td {
  border: none;
}

.attack-table th {
  font-weight: bold;
}

.attack-table thead tr th {
  vertical-align: middle;
}

.attack-table tbody tr td {
  vertical-align: middle;
}

.attack-table td {
  padding: 0;
}

.attack-table td > div {
  padding: 0.75rem;
}

.attack-table td .event-date {
  padding-left: 2px;
  padding-right: 2px;
  width: 85px;
}

.attack-table tbody tr:nth-of-type(2n + 1) {
  background: #f7f7f7;
}

.attack-table tbody tr:nth-of-type(2n) {
  background: #fff;
}

@media (max-width: 900px) {
  .attack-table td:nth-of-type(5) {
    display: none;
  }
  .attack-table th:nth-of-type(5) {
    display: none;
  }
}

@media (max-width: 700px) {
  .attack-table {
    padding-right: 5px;
    padding-left: 5px;
  }

  .attack-table td,
  .attack-table td .event-date {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.6rem;
  }

  .attack-table th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.6875rem;
  }

  .attack-table td .event-date {
    width: unset;
  }

  .attack-table td:nth-of-type(5) {
    display: none;
  }
  .attack-table th:nth-of-type(5) {
    display: none;
  }

  .attack-table .col-md-12 {
    padding: 0;
  }
}

@media (max-width: 399px) {
  .attack-table {
    padding-right: 3px;
    padding-left: 3px;
  }

  .attack-table td,
  .attack-table td .event-date {
    padding-left: 1px;
    padding-right: 1px;
    font-size: 0.5rem;
  }

  .attack-table th {
    padding-left: 2px;
    padding-right: 2px;
    font-size: 0.5625rem;
  }

  .attack-table td .event-date {
    width: unset;
  }

  .attack-table td:nth-of-type(5) {
    display: none;
  }
  .attack-table th:nth-of-type(5) {
    display: none;
  }

  .attack-table .col-md-12 {
    padding: 0;
  }
}

.attack-table td:nth-of-type(5) > div {
  padding-left: 2px;
  padding-right: 2px;
  font-size: 0.8rem;
}

.attack-table tbody {
  font-size: 0.875rem;
}

.attack-table .attack-certainty-box {
  color: #fff;
  display: inline-block;
  margin-top: 3px;
  height: 25px;
  width: 25px;
  border-radius: 50px;
}

.attack-table .attack-certainty-box a {
  height: 25px;
  width: 25px;
  border-radius: 50px;
  display: block;
}

.attack-table .btn-details {
  padding-top: 0px;
}

.attack-table .search-label .form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
}

.attack-table .search-label .form-control:focus {
  box-shadow: none;
}

.attack-table tbody tr td {
  position: relative;
}

.attack-table tbody tr td > div {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.attack-table tbody tr:nth-of-type(2n + 1) td:before {
  background-color: #f7f7f7;
}

.attack-table tbody tr:nth-of-type(2n) td:before {
  background-color: #ffffff;
}

.attack-table tbody tr td:before {
  content: "";
  position: absolute;
  left: -100vw;
  top: 0;
  height: 100%;
  width: 300vw;
  overflow: hidden;
  z-index: 2;
}

.attack-table .react-bootstrap-table-pagination {
  flex-direction: column;
  align-content: center;
}

.attack-table
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list {
  flex-direction: column;
  align-content: center;
}

.attack-table
  .react-bootstrap-table-pagination
  .react-bootstrap-table-pagination-list
  .pagination {
  width: fit-content;
  align-self: center;
  float: unset;
}

.attack-table .react-bootstrap-table-pagination .page-item a {
  border: none;
  margin: 3px;
}

/*.attack-table .react-bootstrap-table-pagination .page-item a:focus {
  box-shadow: 0 0 0 0.2rem #08a85b40;
}

.attack-table .react-bootstrap-table-pagination .page-item[title="previous page"] a ,
.attack-table .react-bootstrap-table-pagination .page-item[title="next page"] a {
  font-size: 1.25rem;
}*/

/*.attack-table .react-bootstrap-table-pagination .page-item[title="previous page"] a span ,
.attack-table .react-bootstrap-table-pagination .page-item[title="next page"] a span {
  width: 1rem;
  display: block;
  position: relative;
  line-height: 1.25;
  margin-top: 5px;
}

.attack-table .react-bootstrap-table-pagination .page-item[title="previous page"] a span {
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 1.6rem solid var(--color-primary);
  margin-right: 50px;
}

.attack-table .react-bootstrap-table-pagination .page-item[title="next page"] a span {
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-left: 1.6rem solid var(--color-primary);
  margin-left: 50px;
}*/
