.voronoi-treemap-container {
  overflow: visible;
}
@media (max-width: 899px) {
  .voronoi-treemap-container {
    overflow: scroll;
  }
}

.voronoi-treemap-container .voronoi-treemap-svg {
  overflow: visible;
}

.voronoi-treemap-container .voronoi-treemap-svg:focus {
  outline: none;
}

.voronoi-treemap-container .cell path {
  stroke: white;
  stroke-width: 1px;
}

.voronoi-treemap-container .label {
  text-anchor: middle;
  fill: white;
}

.voronoi-treemap-container .label > .name {
  dominant-baseline: text-after-edge;
}

.voronoi-treemap-container .label > .value {
  dominant-baseline: text-before-edge;
}

.voronoi-treemap-container .label.yellow {
  font-weight: bold;
}

.voronoi-treemap-container .hoverer path {
  fill: transparent;
  stroke: white;
  stroke-width: 0px;
}

.voronoi-treemap-container .hoverer path:hover {
  stroke-width: 3px;
}

.voronoi-treemap-container .voronoi-graph-tooltip-container {
  overflow: visible;
}

.voronoi-treemap-container
  .voronoi-graph-tooltip-container
  .voronoi-graph-tooltip-container-inner {
  text-align: center;
  border: 2px solid var(--color-blue-ghost-green);
  border-radius: 0.25rem;
  margin-left: 20px;
  padding: 0.2rem;
  font-size: 0.9375rem;
  background: #ffffff;
  min-width: 200px;
  width: fit-content;
}

.voronoi-graph-categories {
  margin-top: 20px;
}

.voronoi-graph-categories,
.voronoi-graph-categories > div,
.voronoi-graph-categories > div > div {
  z-index: -1;
  position: relative;
}
