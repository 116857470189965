tspan.highcharts-text-outline {
  display: none;
}

.nested-parliament {
  height: 385px;
  width: 100%;
  border-radius: 5px;
}

.nested-parliament .titles {
  font-size: 0.875rem;
  line-height: 110%;
  margin-top: -14px;
  margin-bottom: -32px;
}

.nested-parliament svg {
  max-height: 300px !important;
}

.nested-parliament svg {
  margin-top: 32px;
}

@media (min-width: 3000px) {
  .nested-parliament {
    height: 1080px;
    width: 100%;
    border-radius: 40px;
  }

  .nested-parliament .titles {
    font-size: 3.75rem;
    line-height: 110%;
  }

  .nested-parliament .highcharts-container {
    max-height: 1000px !important;
  }

  .nested-parliament svg {
    max-height: 1000px !important;
  }
}
