.map-tooltip-container {
  overflow: visible;
}

.map-tooltip-container .map-tooltip {
  text-align: center;
  border: 2px solid var(--color-blue-ghost-green);
  border-radius: 0.25rem;
  margin-left: 20px;
  padding: 0.2rem;
  font-size: 0.9375rem;
  background: #ffffff;
  width: max-content;
}

.country {
  stroke: #ffffff;
  stroke-width: 0.1;
  fill: #373636;
}

@media (min-width: 3000px) {
  .country {
    stroke-width: 4;
  }
}

.map-container .pie-pieces {
  stroke: #000000;
  stroke-width: 1;
}

.map-container .category-labels {
  overflow: visible;
}

.map-container .category-labels .category-label {
  border: 1px solid #000;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  padding: 0.2rem;
  font-size: 0.9375rem;
  /*background: #ffffff;*/
  width: max-content;
}

@media (max-width: 400px) {
  .map-container .zoom-labels > div:first-of-type {
    margin-top: 42px;
  }
}

.map-container .zoom-labels {
  overflow: visible;
}

.map-container .zoom-labels > div {
  cursor: pointer;
  user-select: none;
}

.map-background {
  fill: #222222;
}
