.visualizations {
  background: var(--color-main-background);
}

.visualizations hr {
  border-top: 1px solid var(--color-blue-ghost-green);
}

.visualizations .title {
  margin-top: 30px;
  text-align: center;
}

.visualizations .title h1 {
  padding-top: 15px;
  font-size: 3.125rem;
  font-weight: 300;
  font-style: normal;
}

.visualizations h3 {
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 41px;
  text-align: center;
  margin-top: 78px;
  margin-bottom: 30px;
}

.visualizations h4.subtitle {
  border-top: unset;
  font-size: 1.5rem;
  text-align: center;
}

.visualizations h5 {
  border-top: 1px solid var(--color-blue-ghost-green);
  color: var(--color-blue-ghost-green);
}

.visualizations .topic-break small {
  color: var(--color-text);
}

.highcharts-axis-title {
  font-size: 0.875rem;
}

.visualizations .data-visualization {
  overflow: hidden;
}

.visuals-tabs {
  margin-bottom: 20px;
  border-top: 1px solid var(--color-blue-ghost-green);
  color: var(--color-blue-ghost-green);
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.visuals-tabs > div {
  display: flex;
}

.visuals-tabs > div .visual-tab-button {
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.visuals-tabs > div .visual-tab-button:first-of-type {
  border-right: 1px solid var(--color-blue-ghost-green);
}

.visuals-tabs > div .visual-tab-button:first-of-type:before {
  content: "";
  height: 100%;
  width: 50vw;
  z-index: -1;
  top: 0;
  right: 0;
  position: absolute;
  background: var(--color-blue-ghost-lightgray);
}

.visuals-tabs > div .visual-tab-button.active:first-of-type:before {
  background: var(--color-main-background);
}

.visuals-tabs > div .visual-tab-button:nth-of-type(2):before {
  content: "";
  height: 100%;
  width: 50vw;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  background: var(--color-blue-ghost-lightgray);
}

.visuals-tabs > div .visual-tab-button.active:nth-of-type(2):before {
  background: var(--color-main-background);
}

.visuals-tabs > div .visual-tab-button {
  background: var(--color-blue-ghost-lightgray);
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.visuals-tabs > div .visual-tab-button.active {
  background: var(--color-main-background);
}

.after-filter-text {
  margin-top: 70px;
  margin-bottom: 50px;
  color: var(--color-blue-ghost-green);
  font-size: 1.875rem;
  font-weight: 300;
  line-height: 35px;
  text-align: center;
}

.switch {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  --line: #505162;
  --dot: #f7f8ff;
  --circle: #9ea0be;
  --duration: 0.3s;
  --text: #9ea0be;
  cursor: pointer;
}
.switch input {
  display: none;
}
.switch input + div {
  position: relative;
}
.switch input + div:before,
.switch input + div:after {
  --s: 1;
  content: "";
  position: absolute;
  height: 4px;
  top: 10px;
  width: 24px;
  background: var(--line);
  transform: scaleX(var(--s));
  transition: transform var(--duration) ease;
}
.switch input + div:before {
  --s: 0;
  left: 0;
  transform-origin: 0 50%;
  border-radius: 2px 0 0 2px;
}
.switch input + div:after {
  left: 28px;
  transform-origin: 100% 50%;
  border-radius: 0 2px 2px 0;
}
.switch input + div span {
  padding-left: 56px;
  line-height: 24px;
  color: var(--text);
}
.switch input + div span:before {
  --x: 0;
  --b: var(--circle);
  --s: 4px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 var(--s) var(--b);
  transform: translateX(var(--x));
  transition: box-shadow var(--duration) ease, transform var(--duration) ease;
}
.switch input + div span:not(:empty) {
  padding-left: 64px;
}
.switch input:checked + div:before {
  --s: 1;
}
.switch input:checked + div:after {
  --s: 0;
}
.switch input:checked + div span:before {
  --x: 28px;
  --s: 12px;
  --b: var(--dot);
}
