.percentage-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid var(--color-secondary-black);
  overflow: hidden;
  height: 100%;
}

.percentage-row-with-img {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

.percentage-row-with-img > img {
  width: 40%;
}

.percentage-row-with-img .percentage-row {
  width: 60%;
}

.percentage-block {
  text-align: center;
  height: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.percentage-block {
  background: var(--color-blue-ghost-green);
}

.percentage-container .disclaimer {
  font-style: italic;
  font-size: 0.6rem;
  height: 12px;
}
