.filter-periods {
  display: flex;
  margin-bottom: 100px;
}

.filter-periods > .filter-period {
  background: #222222;
  color: #ffffff;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  text-align: center;
}

.filter-periods > .filter-period.previous {
  background: rgba(0, 0, 0, 0.5);
}

.filter-periods > .filter-period > .filter-period-header {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 35px;
  line-height: 21px;
  font-size: 1rem;
}

.filter-periods > .filter-period > .filter-period-header strong {
  font-size: 1rem;
  font-weight: 700;
}

.filter-periods > .filter-period > .filter-period-data {
  width: calc(100% / 4);
  font-size: 5.625rem;
  line-height: 80px;
  margin-bottom: 15px;
  text-align: center;
}

.filter-periods > .filter-period > .filter-period-footer {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 35px;
  line-height: 21px;
  font-size: 1rem;
}

.filter-periods > .filter-period:first-of-type {
  padding-right: 10px;
}

.filter-periods > .filter-period.current {
  padding-left: 10px;
}

.filter-periods > .filter-period:first-of-type {
  padding-left: 30px;
}

.filter-periods > .filter-period.current {
  padding-right: 30px;
}

.filter-periods > .filter-period.previous > .filter-period-data {
  font-family: "Montserrat - Hairline";
}

.filter-periods > .filter-period.current > .filter-period-data {
  font-weight: 700;
}

.filter-periods > .filter-period > .filter-period-data-subtitle {
  width: calc(100% / 4);
  margin-bottom: 15px;
  font-size: 1.25rem;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
}

.filter-periods > .filter-period.previous .comparison {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 124px;
  text-align: center;
  content: "asdf";
}

@media (max-width: 319px) {
  .filter-periods > .filter-period > .filter-period-header {
    padding-top: 25px;
    padding-bottom: 5px;
    line-height: 20px;
    font-size: 0.6875rem;
  }

  .filter-periods > .filter-period > .filter-period-data {
    font-size: 1.75rem;
    line-height: 65px;
    margin-bottom: 0px;
    text-align: center;
  }

  .filter-periods > .filter-period > .filter-period-data-subtitle {
    font-size: 0.6rem;
    line-height: 14px;
  }

  .filter-periods > .filter-period > .filter-period-footer {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 20px;
    font-size: 0.6875rem;
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) {
  .filter-periods > .filter-period > .filter-period-header {
    padding-top: 25px;
    padding-bottom: 15px;
    line-height: 20px;
    font-size: 0.6875rem;
  }

  .filter-periods > .filter-period > .filter-period-data {
    font-size: 2.375rem;
    line-height: 70px;
    margin-bottom: 0px;
    text-align: center;
  }

  .filter-periods > .filter-period > .filter-period-data-subtitle {
    font-size: 0.6875rem;
    line-height: 14px;
  }

  .filter-periods > .filter-period > .filter-period-footer {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 20px;
    font-size: 0.6875rem;
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .filter-periods > .filter-period.previous .comparison > div {
    width: 50px;
    height: 50px;
    margin-top: -85px;
    padding-top: 15px;
    font-size: 0.875rem;
  }

  .filter-periods > .filter-period > .filter-period-data {
    font-size: 4.375rem;
    line-height: 80px;
    margin-bottom: 15px;
    text-align: center;
  }

  .filter-periods > .filter-period > .filter-period-data-subtitle {
    font-size: 1.25rem;
    line-height: 24px;
  }

  .filter-periods > .filter-period > .filter-period-footer {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .filter-periods > .filter-period.previous .comparison > div {
    width: 70px;
    height: 70px;
  }

  .filter-periods > .filter-period.previous .comparison .number {
    margin-top: -70px;
    padding-top: 25px;
    font-size: 1rem;
  }

  .filter-periods > .filter-period.previous .comparison .arrow {
    margin-top: -15px;
    padding-top: 18px;
  }

  .filter-periods > .filter-period.previous .comparison .arrow img {
    width: 33px;
    height: 33px;
  }

  .filter-periods > .filter-period > .filter-period-data {
    font-size: 5.625rem;
    line-height: 80px;
    margin-bottom: 15px;
    text-align: center;
  }
}

@media (min-width: 1600px) {
  .filter-periods > .filter-period.previous .comparison > div {
    width: 100px;
    height: 100px;
  }

  .filter-periods > .filter-period.previous .comparison .number {
    margin-top: -95px;
    padding-top: 35px;
    font-size: 1.25rem;
  }

  .filter-periods > .filter-period.previous .comparison .arrow {
    margin-top: -20px;
    padding-top: 25px;
  }

  .filter-periods > .filter-period.previous .comparison .arrow img {
    width: 50px;
    height: 50px;
  }
}

.filter-periods > .filter-period.previous .comparison .number {
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 500px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
}

.filter-periods > .filter-period.previous .comparison .arrow {
  background-color: #ffffff;
  color: #fd0303;
  border: 1px solid #fd0303;
  border-radius: 500px;
  margin-left: auto;
  margin-right: auto;
}
