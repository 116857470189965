.nav-bar {
  z-index: 40;
  box-shadow: 2px 3px 3px rgb(0 0 0 / 10%);
}

.nav-bar nav {
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
}

.nav-bar img {
  max-width: 100%;
  padding: 0.3rem 0;
  box-shadow: none;
}

.nav-bar .active {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .nav-bar nav .logo {
    width: 30%;
  }

  .nav-bar nav .tabs {
    width: 70%;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .nav-bar nav .logo {
    width: 45%;
  }

  .nav-bar nav .tabs {
    width: 55%;
  }
}
