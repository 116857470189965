:root {
  --color-primary: #04a95b;
  --color-primary-alternate: #18a953;
  --color-secondary: #2f2f2f;
  --color-primary-light: #f2fbf5;
  --color-secondary-black: #202f33;
  --color-text: #5a5a5a;
  --color-accent: #00aa5a;
  --color-main-background: #ecf0f4;
  --color-b488c42: #07a959;
  --color-ecf6ec5: #5a5a5a;
  --color-ebcbfd5: #f2fbf6;
  --color-6480352: #c9c9c9;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  src: url(./utils/fonts/Montserrat.ttf);
}

@font-face {
  font-family: "Montserrat - Hairline";
  src: url(./utils/fonts/montserrat/Montserrat-Hairline.otf);
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border-top: 1px solid var(--color-primary);
}

hr.white {
  border-top-color: #fff;
}

p {
  margin: revert;
}
