.app {
  --color-primary: #04a95b;
  --color-blue-ghost-green: #18a953;
  --color-blue-ghost-lightgray: #f6f6f6;
  --color-primary-light: #f2fbf5;
  --color-secondary-black: #202f33;
  --color-text: #5a5a5a;
  --color-accent: #00aa5a;
  /*--color-main-background: #172944;*/
  --color-main-background: #ffffff;
  --color-b488c42: #07a959;
  --color-ecf6ec5: #5a5a5a;
  --color-ebcbfd5: #f2fbf6;
  --color-6480352: #c9c9c9;
  color: var(--color-text);
  font-size: 1rem;
  line-height: 22px;
  letter-spacing: 0;
}

.content {
  min-height: 50vh;
}

.content h1 {
  color: var(--color-primary);
}

.content h2,
.content h3,
.content h4 {
  color: var(--color-primary);
}

.text-block {
  color: var(--color-primary);
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 1rem;
}

.text-block strong {
  font-weight: 800;
}

.text-block.topic-break,
.topic-break {
  margin-top: 25px;
  margin-bottom: 15px;
}

.attack-certainty-box {
  color: #fff;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-bottom: -3px;
  margin-right: 5px;
  border-radius: 50px;
}

.attack-certainty-box > span > div {
  border-radius: 50px;
  height: 25px;
  width: 25px;
}

.attack-certainty-box.possible {
  background: #fc8810;
}

.attack-certainty-box.probable {
  background: #f4cf70;
}

.attack-certainty-box.confirmed {
  background: #04a95b;
}

.highcharts-container {
  border-radius: 5px;
  box-shadow: 0px 1px #10101059;
}

.highcharts-background {
  fill: var(--color-main-background);
}

.country-toolbox {
  border-radius: 50px;
  height: 30px;
  background: #fff;
  width: 30px;
  overflow: hidden;
  display: inline-block;
  box-shadow: 0px 1px #10101059;
}

.round-flag {
  border-radius: 50px;
  height: 30px;
  width: 30px;
  box-shadow: 0px 1px #10101059;
}

h1 {
  font-weight: 200;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.highcharts-outer-container {
  height: 400px;
  border-radius: 5px;
}

.map-container {
  position: relative;
  height: 500px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 3px #10101059;
}

@media (min-width: 3000px) {
  .highcharts-outer-container {
    height: 1000px;
  }

  .map-container {
    height: 1200px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
