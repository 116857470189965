.footer-burrito {
  background: #414141;
}

footer .footer-trademark {
  padding-top: 40px;
  padding-bottom: 5px;
  color: #ffffff;
}

footer .footer-icons {
  padding-top: 50px;
}

footer .footer-icon {
  text-decoration: none;
  color: #ffffffb8;
}

footer .footer-icon i {
  font-size: 1.25rem;
  padding-left: 20px;
}

@media screen and (min-width: 768px) {
  .footer-site {
    grid-template-columns: 1fr 1fr 0.5fr 1fr 1fr;
    gap: 0;
  }
}
