.timebar-chart {
  overflow: visible;
}
@media (max-width: 899px) {
  .timebar-chart {
    overflow: scroll;
  }
}

.timebar-chart > svg {
  overflow: visible;
}

.timebar-chart > svg:focus {
  outline: none;
}

.timebar-chart > svg rect {
  transition: fill 0.5s;
}

.timebar-chart > svg .parent {
  cursor: pointer;
}

.timebar-chart .xAxis .domain,
.timebar-chart .yAxis .domain {
  display: none;
}

@media (min-width: 3000px) {
  .timebar-chart .tick text {
    font-size: 2.4375rem;
    fill: #cdcdcd;
  }

  .timebar-chart .yAxis .tick text {
    transform: translate(-20px, 0px);
  }

  .timebar-chart .xAxis .tick text {
    transform: translate(0px, 20px);
  }
}

.timebar-chart .xAxis .tick line,
.timebar-chart .yAxis .tick line {
  stroke: #cdcdcd;
}

.timebar-chart .timebar-chart-tooltip-container {
  overflow: visible;
}

.timebar-chart
  .timebar-chart-tooltip-container
  .timebar-chart-tooltip-container-inner {
  text-align: center;
  border: 2px solid var(--color-blue-ghost-green);
  border-radius: 0.25rem;
  margin-left: 20px;
  padding: 0.2rem;
  font-size: 0.9375rem;
  background: #ffffff;
  min-width: 200px;
  width: fit-content;
}

.timebar-chart .drillup-container {
  overflow: visible;
}

.timebar-chart .timebar-chart-categories,
.timebar-chart .timebar-chart-categories > div,
.timebar-chart .timebar-chart-categories > div > div {
  position: relative;
}

.timebar-chart .timebar-chart-categories {
  margin-top: 10px;
  margin-bottom: 40px;
}
