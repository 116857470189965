.home-section > * {
  margin-left: auto;
  margin-right: auto;
  max-width: 39rem;
}

.home-section .max-w-full {
  max-width: 100%;
}

.home-section-numbers {
  background-color: #000;
  color: #fff;
  text-align: center;
}

.home-section-help {
  background: var(--color-primary);
  color: #fff;
}
