@media (max-width: 1023px) {
  .filter-form .form-field-container {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  @supports ((position: sticky)) {
    .sticky-filter {
      position: sticky;
      top: 64px;
      z-index: 40;
    }
  }

  .sticky-filter {
    top: 64px;
  }
  .filter-form .form-field-container {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .sticky-filter {
    top: 64px;
  }
  .filter-form .form-field-container {
    width: 1068px;
  }
}

.sticky-filter {
  width: fit-content;
  margin: auto;
}

.filter-bar-container {
  display: flex;
  transition: all 0.6s;
}

.filter-form {
  display: flex;
  background: var(--color-main-background);
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.filter-form .form-field-container {
  display: flex;
  justify-content: space-between;
}
.form-field-container > div {
  width: 100%;
}

.filter-form .select > div {
  border-color: var(--color-blue-ghost-green);
}

.filter-form .select .css-1okebmr-indicatorSeparator {
  background-color: var(--color-blue-ghost-green);
}

.filter-form .select .css-1wa3eu0-placeholder,
.filter-form .select .css-tlfecz-indicatorContainer,
.filter-form .select .css-12jo7m5 {
  color: var(--color-blue-ghost-green);
}

.filter-form .select .css-1rhbuit-multiValue {
  padding-left: 1px;
  padding-right: 3px;
  border-radius: 13px;
}

.filter-form .select .css-xb97g8 {
  background: #fff;
  color: var(--color-blue-ghost-green);
  border-radius: 50px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
}

.filter-form .select .css-xb97g8:hover {
  background-color: #ffbdad;
  color: #de350b;
}

.select .round-flag {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.filter-arrow .fa.closed {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.form-element .select {
  margin-bottom: 10px;
}

.form-element.clear-form {
  margin-top: 10px;
}

.form-element .select {
  width: 92%;
}

.date-input-fields .react-datepicker__tab-loop {
  display: inline-block;
}

.date-input-fields input {
  width: 100% !important;
}

.date-input-fields input#fromDate {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.date-input-fields input#toDate {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

input.form-element {
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-blue-ghost-green);
  background-color: #fff;
  border: 1px solid var(--color-blue-ghost-green);
  border-radius: 0.25rem;
}

.form-element.clear-form {
  display: flex;
  justify-content: center;
}

.form-element.clear-form .clear-form-button {
  cursor: pointer;
  text-align: center;
  width: fit-content;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-blue-ghost-green);
  background-color: var(--color-main-background);
  border: 1px solid var(--color-main-background);
  border-radius: 0.25rem;
}

.date-input-fields input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-blue-ghost-green) !important;
  opacity: 1; /* Firefox */
}

.date-input-fields input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-blue-ghost-green) !important;
}

.filter-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;

  color: #fff;
  background-color: var(--color-accent);
  border: 1px solid var(--color-secondary-black);
  border-left-width: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

@media (max-width: 700px) {
  .filter-arrow {
    width: 18px;
    font-size: 0.875rem;
  }
}
