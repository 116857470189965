.single-attack .nav-tabs {
  margin-bottom: 1rem;
}

.single-attack dl {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0.5rem 1rem;
  margin: 1rem 0;
}

.single-attack .btn-link:focus {
  box-shadow: none;
}

.single-attack .btn-link {
  color: #18a953;
}

.single-attack dl a {
  color: #18a953;
}

.single-attack .source-level-title {
  margin-top: 10px;
  margin-bottom: 10px;
}

.single-attack hr {
  border-color: #dee2e6;
}

.single-attack h3,
.single-attack h4,
.single-attack h5 {
  font-weight: bold;
}
.single-attack .columns h4 {
  text-align: center;
}

.single-attack h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.columns > :first-child {
  padding-right: 1rem;
  border-right: 1px solid #dee2e6;
}

.columns > :last-child {
  margin-left: 1rem;
}
