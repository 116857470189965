.network-graph-svg {
  margin-top: 40px;
  margin-bottom: -20px;
  overflow: visible;
}

.network-graph-svg:focus {
  outline: none;
}

.network-graph-tooltip-container {
  overflow: visible;
}

.network-graph-tooltip-container .map-tooltip {
  text-align: center;
  border: 2px solid var(--color-blue-ghost-green);
  border-radius: 0.25rem;
  margin-left: 20px;
  padding: 0.2rem;
  font-size: 0.9375rem;
  background: #ffffff;
  min-width: 200px;
  width: fit-content;
}

.network-graph-categories,
.network-graph-categories > div,
.network-graph-categories > div > div {
  z-index: -1;
  position: relative;
}

@media (max-width: 899px) {
  .network-graph-container {
    overflow: scroll;
  }

  .network-graph-categories {
    width: fit-content;
    margin-bottom: 10px;
  }
}
